import React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  backgroundColor: theme.palette.primary.main,
  ...theme.mixins.toolbar,
}));

const iconMapping = {
  dashboard: <DashboardIcon />,
  users: <PersonIcon />,
  groups: <GroupIcon />,
};

const Sidebar = ({ handleDrawerClose, open, onSelectPage }) => {
  const pages = ['Dashboard', 'Users', 'Groups'];

  return (
    <Drawer variant="persistent" anchor="left" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {pages.map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => onSelectPage(text.toLowerCase())}>
              <ListItemIcon
                sx={{
                  display: 'flex',
                  minWidth: '36px',
                  justifyContent: 'center',
                  visibility: open ? 'visible' : 'hidden',
                }}
              >
                {iconMapping[text.toLowerCase()]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
