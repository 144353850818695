// Groups.js
import React, { useState, useEffect } from 'react';
import TableComponent from './TableComponent';
import { useMsal } from '@azure/msal-react';

const Groups = ({ onSelectGroup }) => {
  const { instance, accounts } = useMsal();
  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    const fetchGroupsData = async () => {
      try {
        if (accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['Group.Read.All'],
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          const graphResponse = await fetch('https://graph.microsoft.com/v1.0/groups', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const groups = await graphResponse.json();
          setGroupData(groups.value);
        }
      } catch (error) {
        console.error('Error fetching groups from Microsoft Graph API:', error);
      }
    };

    fetchGroupsData();
  }, [instance, accounts]);

  const getTypeLabel = (group) => {
    if (group.securityEnabled) {
      return group.mailEnabled ? 'Mail-Enabled Security Group' : 'Security Group';
    } else if (group.mailEnabled) {
      return 'M365 Group';
    } else {
      return 'Unknown Type';
    }
  };

  const formatGroupDataForTable = () => {
    return groupData.map((group) => ({
      Name: group.displayName,
      Description: group.description || 'No description available',
      Type: getTypeLabel(group),
      ID: group.id,
      // Add more properties as needed
    }));
  };

  const headers = ['Name', 'Description', 'Type', 'ID'];

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <div style={{ overflowY: 'auto', height: '90%' }}>
        <TableComponent
          headers={headers}
          data={formatGroupDataForTable()}
          onRowClick={(group) => {
            console.log('Group clicked:', group.ID);
            onSelectGroup(group.ID);
          }}
        />
      </div>
    </div>
  );
};

export default Groups;
