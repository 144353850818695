import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';

const TableComponent = ({ headers, data, onRowClick }) => {
  const [sorting, setSorting] = useState({ column: null, order: 'asc' });
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column,
      order: prevSorting.column === column && prevSorting.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sorting.column];
    const bValue = b[sorting.column];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sorting.order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else {
      return sorting.order === 'asc' ? aValue - bValue : bValue - aValue;
    }
  });

  return (
    <TableContainer component={Paper} style={{ height: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={index}
                onClick={() => handleSort(header)}
                onMouseEnter={() => setHoveredColumn(header)}
                onMouseLeave={() => setHoveredColumn(null)}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  position: 'relative',
                }}
              >
                {header}
                {hoveredColumn === header && (
                  <FilterListIcon
                    style={{
                      position: 'absolute',
                      right: '4px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      opacity: 0.5,
                    }}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, rowIndex) => (
            <TableRow key={rowIndex} onClick={() => onRowClick(row)} style={{ cursor: 'pointer' }}>
              {headers.map((header, colIndex) => (
                <TableCell key={colIndex}>{row[header]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
