import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from './components/pages/Dashboard';
import Users from './components/pages/Users';
import UserDetails from './components/pages/UserDetails';
import Groups from './components/pages/Groups';
import GroupDetails from './components/pages/GroupDetails';
import { BrowserAuthError } from '@azure/msal-browser';
import theme from './theme';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';


const App = () => {
  const [open, setOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState('dashboard');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { instance, accounts, inProgress } = useMsal();
  const { loading } = useMsalAuthentication();

  useEffect(() => {
    const initMsal = async () => {
      try {
        if (!loading && !inProgress && accounts.length === 0) {
          await instance.loginPopup();
        }
      } catch (error) {
        // Handle user cancellation error
        if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
          console.log('User cancelled the login.');
        } else {
          // Handle other errors
          console.error(error);
        }
      }
    };  
  
    initMsal();
  }, [instance, accounts, inProgress, loading]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onSelectPage = (page) => {
    setSelectedPage(page);
    setSelectedUserId(null);
    setSelectedGroupId(null);
  };

  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
    setSelectedGroupId(null);
    setSelectedPage('user-details');
  };

  const handleSelectGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setSelectedUserId(null);
    setSelectedPage('group-details');
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex' }}>
        <Header handleDrawerOpen={handleDrawerOpen} open={open} />
        <Sidebar handleDrawerClose={handleDrawerClose} open={open} onSelectPage={onSelectPage} />
        <div
          style={{
            flexGrow: 1,
            p: 3,
            paddingTop: '70px',
            transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1)',
            marginLeft: open ? '140px' : '0',
            backgroundColor: theme.palette.background.default,
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            <React.Fragment>
              <AuthenticatedTemplate>
                {selectedPage === 'dashboard' ? (
                  <Dashboard />
                ) : selectedPage === 'users' ? (
                  <Users onSelectUser={handleSelectUser} />
                ) : selectedPage === 'user-details' ? (
                  <UserDetails userId={selectedUserId} />
                ) : selectedPage === 'groups' ? (
                  <Groups onSelectGroup={handleSelectGroup} />
                ) : selectedPage === 'group-details' ? (
                  <GroupDetails groupId={selectedGroupId} />
                ) : (
                  <div>This is a placeholder text for the content area.</div>
                )}
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <div style={{ textAlign: 'center', marginTop: '35vh', transform: 'translateY(-50%)' }}>
                  <Typography variant="h5" gutterBottom>
                    Please sign in to access the app
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => instance.loginPopup().catch(() => {})}
                  >
                    Sign In
                  </Button>
                </div>
              </UnauthenticatedTemplate>
            </React.Fragment>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
