import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { useMsal } from '@azure/msal-react';

const Dashboard = () => {
  const theme = useTheme();
  const { accounts, instance } = useMsal();
  const [totalUsers, setTotalUsers] = useState('Counting...');
  const [enabledUsers, setEnabledUsers] = useState('Counting...');
  const [totalGroups, setTotalGroups] = useState('Counting...');

  useEffect(() => {
    const fetchData = async (url, stateSetter) => {
      try {
        if (accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'User.ReadBasic.All'],
            account: accounts[0],
          });

          const dataResponse = await fetch(url, {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          });

          if (dataResponse.ok) {
            const data = await dataResponse.json();
            const count = data.value.length;
            stateSetter(count);
          } else {
            console.error(`Failed to fetch data from ${url}:`, dataResponse.status, dataResponse.statusText);
          }
        }
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData('https://graph.microsoft.com/v1.0/users', setTotalUsers);
    fetchData('https://graph.microsoft.com/v1.0/users?$filter=accountEnabled eq true', setEnabledUsers);
    fetchData('https://graph.microsoft.com/v1.0/groups', setTotalGroups);
  }, [accounts, instance]);

  return (
    <div style={{ padding: '20px' }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Welcome to the Dashboard!
          </Typography>
        </CardContent>
      </Card>

      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          <Card
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Total Users
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {totalUsers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Enabled Users
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {enabledUsers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Groups
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {totalGroups}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
