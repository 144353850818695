import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { styled, keyframes } from '@mui/system';  // Move this import to the top
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

const UserDetailsContainer = styled('div')({
  padding: '20px',
  overflowY: 'auto',
  maxHeight: '80vh', // Adjust the maxHeight according to your design
});

const UserDetailsCard = styled(Card)({
  width: '85%', // Adjust the width as needed
  margin: 'auto',
  marginTop: '20px',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const colorChange = keyframes({
  '0%': {
    color: '#757575', // Original color
  },
  '50%': {
    color: 'black', // Transition color
  },
  '100%': {
    color: '#757575', // Original color
  },
});

const CopyButton = styled(FileCopyIcon)({
  marginRight: '5px',
  fontSize: '20px',
  color: '#757575',
  cursor: 'pointer',
  opacity: 0,
  transition: 'opacity 0.4s',
  '&:active': {
    opacity: 1,
    animation: `${colorChange} .5s`, // Set the animation duration
  },
});

const CopyContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&:hover': {
    '& svg': {
      opacity: 1,
    },
  },
});

const UserDetails = ({ userId }) => {
  const { instance, accounts } = useMsal();
  const [userData, setUserData] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [hasProfilePicture, setHasProfilePicture] = useState(false);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (userId && accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read.All'],
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          const userDataResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const userData = await userDataResponse.json();
          console.log('Fetched user data:', userData);
          setUserData(userData);

          // Fetch profile picture
          const photoResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (photoResponse.ok) {
            const blob = await photoResponse.blob();
            setProfilePicture(URL.createObjectURL(blob));
            setHasProfilePicture(true);
          }
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserData();
  }, [userId, instance, accounts]);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        if (userId && accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read.All', 'Group.Read.All'],
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          const groupsResponse = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/memberOf`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (groupsResponse.ok) {
            const groupsData = await groupsResponse.json();
            console.log('Fetched user groups:', groupsData.value);
            setUserGroups(groupsData.value);
          }
        }
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    fetchUserGroups();
  }, [userId, instance, accounts]);

  const getInitials = (name) => {
    if (!name) return '';
    const nameArray = name.split(' ');
    return nameArray.length >= 2
      ? nameArray[0][0].toUpperCase() + nameArray[nameArray.length - 1][0].toUpperCase()
      : nameArray[0][0].toUpperCase();
  };

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <UserDetailsContainer>
      <UserDetailsCard variant="outlined">
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {hasProfilePicture ? (
                <Avatar alt={userData?.displayName} src={profilePicture} sx={{ width: 100, height: 100 }} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, bgcolor: '#bdbdbd' }}>
                  <Typography variant="h3" sx={{ lineHeight: '1.5' }}>
                    {getInitials(userData?.displayName)}
                  </Typography>
                </Avatar>
              )}
            </Grid>
            <Grid item>
              <Typography variant="h4" component="div">
                {userData && userData.displayName}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" color="textSecondary" className="noHover" marginTop="10px">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userId)} />
              User ID: {userId}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.mail || '')} />
              Email: {userData && userData.mail}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.jobTitle || 'N/A')} />
              Job Title: {userData && (userData.jobTitle || 'N/A')}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.preferredLanguage || 'N/A')} />
              Preferred Language: {userData && (userData.preferredLanguage || 'N/A')}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.givenName || 'N/A')} />
              Given Name: {userData && (userData.givenName || 'N/A')}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.surname || 'N/A')} />
              Surname: {userData && (userData.surname || 'N/A')}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.officeLocation || 'N/A')} />
              Office Location: {userData && (userData.officeLocation || 'N/A')}
            </CopyContainer>
          </Typography>
          <Typography variant="body2" color="textSecondary" className="noHover">
            <CopyContainer>
              <CopyButton onClick={() => handleCopyClick(userData?.mobilePhone || 'N/A')} />
              Mobile Phone: {userData && (userData.mobilePhone || 'N/A')}
            </CopyContainer>
          </Typography>

          {/* User Groups */}
          <Typography variant="h5" component="div" style={{ marginTop: '20px', marginBottom: '10px' }}>
            User Groups
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {userGroups.map((group, index) => (
                  group.displayName && (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row">
                        {group.displayName} - {group.description || 'No Description'}
                      </TableCell>
                      {/* Add more cells if needed */}
                    </TableRow>
                  )
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </UserDetailsCard>
    </UserDetailsContainer>
  );
};

export default UserDetails;
