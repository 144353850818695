// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser'; // Correct import
import App from './App';

const msalConfig = {
  auth: {
    clientId: '008c871e-01cb-4e3e-b923-37a2a98f9c66',
    authority: 'https://login.microsoftonline.com/b505e40c-a82c-4d4a-9456-e14cf816ce49',
    redirectUri: 'https://portal.leapit.us',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
