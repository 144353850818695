import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', // Blue
    },
    secondary: {
      main: '#FF5722', // Orange
    },
    background: {
      default: '#F5F5F5', // Light gray background
      paper: '#FFFFFF', // White background for paper elements
    },
    text: {
      primary: '#212121', // Dark text
      secondary: '#757575', // Lighter text for secondary information
    },
  },
});

export default theme;
