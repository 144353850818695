import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import { useMsal } from '@azure/msal-react';

const StyledAppBar = styled(AppBar)({
  backgroundColor: (theme) => theme.palette.primary.main,
});

const StyledIconButton = styled(IconButton)({
  // Add styles for your menu icon
});

const Header = ({ handleDrawerOpen, open }) => {
  const { instance, accounts } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);

  useEffect(() => {
    const fetchUserAvatar = async () => {
      try {
        if (accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'User.ReadBasic.All'],
            account: accounts[0],
          });

          // Fetch user avatar
          const avatarResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          });

          if (avatarResponse.ok) {
            const avatarBlob = await avatarResponse.blob();
            setUserAvatar(URL.createObjectURL(avatarBlob));
          }
        }
      } catch (error) {
        // Handle errors
        console.error('Error fetching user avatar:', error);
      }
    };

    fetchUserAvatar();
  }, [accounts, instance]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = async () => {
    try {
      await instance.loginPopup();
    } catch (error) {
      // If the user cancels the login, the error will be caught here
      if (error.errorMessage === 'User cancelled the flow') {
        console.log('User cancelled the login');
      } else {
        // Handle other errors
        console.error('Login error:', error);
      }
    }
    handleMenuClose();
  };

  const handleSignOut = () => {
    instance.logout();
    handleMenuClose();
  };

  return (
    <StyledAppBar position="fixed" open={open}>
      <Toolbar sx={{ transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1)' }}>
        <StyledIconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </StyledIconButton>

        <div style={{ flexGrow: 1 }} />

        <Typography variant="h6" noWrap component="div" sx={{ transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1)' }}>
          Leap IT
        </Typography>

        <IconButton color="inherit" onClick={handleMenuClick}>
          <Avatar alt="User Avatar" src={userAvatar} />
        </IconButton>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {accounts.length === 0 ? (
            <MenuItem onClick={handleSignIn}>Sign In</MenuItem>
          ) : (
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          )}
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
