// Users.js
import React, { useState, useEffect } from 'react';
import TableComponent from './TableComponent';
import { useMsal } from '@azure/msal-react';

const Users = ({ onSelectUser }) => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        if (accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read.All'],
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          const graphResponse = await fetch('https://graph.microsoft.com/v1.0/users', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const userData = await graphResponse.json();
          console.log('Fetched user list:', userData.value);
          setGraphData(userData.value);
        }
      } catch (error) {
        console.error('Error fetching users from Microsoft Graph API:', error);
      }
    };

    fetchUsersData();
  }, [instance, accounts]);

  const headers = ['Name', 'Email']; // Add more headers as needed

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <div style={{ overflowY: 'auto', height: '90%' }}>
        <TableComponent
          headers={headers}
          data={graphData.map((user) => ({
            Name: user.displayName,
            Email: user.userPrincipalName,
            // Add more properties as needed
          }))}
          onRowClick={(user) => {
            console.log('User clicked:', user);
            onSelectUser(user.Email); // Ensure that onSelectUser is called with the correct user ID
          }}
        />
      </div>
    </div>
  );
};

export default Users;
