// GroupDetails.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { styled, keyframes } from '@mui/system';  // Move this import to the top
import FileCopyIcon from '@mui/icons-material/FileCopy';

const GroupDetailsContainer = styled('div')({
  padding: '20px',
  overflowY: 'auto',
  maxHeight: '100vh', // Adjust the maxHeight according to your design
});

const GroupDetailsCard = styled(Card)({
  width: '85%', // Adjust the width as needed
  margin: 'auto',
  marginTop: '20px',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  maxHeight: 'none', // Allow the card to grow according to its content
  overflow: 'visible', // Allow the content to overflow the card
});

const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'none', // Allow the container to grow according to its content
  overflowY: 'visible', // Allow the content to overflow the container
});

const colorChange = keyframes({
  '0%': {
    color: '#757575', // Original color
  },
  '50%': {
    color: 'black', // Transition color
  },
  '100%': {
    color: '#757575', // Original color
  },
});

const CopyButton = styled(FileCopyIcon)({
  marginRight: '5px',
  fontSize: '20px',
  color: '#757575',
  cursor: 'pointer',
  opacity: 0,
  transition: 'opacity 0.4s',
  '&:active': {
    opacity: 1,
    animation: `${colorChange} .5s`, // Set the animation duration
  },
});

const CopyContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&:hover': {
    '& svg': {
      opacity: 1,
    },
  },
});

const GroupDetails = ({ groupId }) => {
  const { instance, accounts } = useMsal();
  const [groupData, setGroupData] = useState(null);
  const [groupMembers, setGroupMembers] = useState([]);

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        if (groupId && accounts.length > 0) {
          const response = await instance.acquireTokenSilent({
            scopes: ['Group.Read.All'],
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          const groupResponse = await fetch(`https://graph.microsoft.com/v1.0/groups/${groupId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (groupResponse.ok) {
            const groupData = await groupResponse.json();
            console.log('Fetched group data:', groupData);
            setGroupData(groupData);

            // Fetch group members
            const membersResponse = await fetch(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            if (membersResponse.ok) {
              const membersData = await membersResponse.json();
              console.log('Fetched group members:', membersData.value);
              setGroupMembers(membersData.value);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
    };

    fetchGroupData();
  }, [groupId, instance, accounts]);

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <GroupDetailsContainer>
      {groupData && (
        <GroupDetailsCard variant="outlined">
          <CardContent>
            <Typography variant="h4" component="div">
              {groupData.displayName}
            </Typography>
            <Typography variant="body1" color="textSecondary" className="noHover" marginTop="10px">
              <CopyContainer>
                <CopyButton onClick={() => handleCopyClick(groupId)} />
                Group ID: {groupId}
              </CopyContainer>
            </Typography>
            <Typography variant="body2" color="textSecondary" className="noHover">
              <CopyContainer>
                <CopyButton onClick={() => handleCopyClick(groupData.description || 'N/A')} />
                Description: {groupData.description || 'N/A'}
              </CopyContainer>
            </Typography>
            <Typography variant="body2" color="textSecondary" className="noHover">
              <CopyContainer>
                <CopyButton onClick={() => handleCopyClick(groupData.mail || 'N/A')} />
                Mail: {groupData.mail || 'N/A'}
              </CopyContainer>
            </Typography>
            <Typography variant="body2" color="textSecondary" className="noHover">
              <CopyContainer>
                <CopyButton onClick={() => handleCopyClick(groupData.mailEnabled ? 'Yes' : 'No')} />
                Mail Enabled: {groupData.mailEnabled ? 'Yes' : 'No'}
              </CopyContainer>
            </Typography>
            {/* Add more attributes with the clipboard icon as needed */}
            {/* ... */}
            <Typography variant="h5" component="div" style={{ marginTop: '20px', marginBottom: '10px' }}>
              Members
            </Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableBody>
                  {groupMembers.map((member, index) => (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row">
                        {member.displayName} - {member.userPrincipalName}
                      </TableCell>
                      {/* Add more cells if needed */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </CardContent>
        </GroupDetailsCard>
      )}
    </GroupDetailsContainer>
  );
};

export default GroupDetails;
